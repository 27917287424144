<template>
  <div class="grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 mx-auto mt-16 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
    <figure class="hidden col-span-2 sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
      <blockquote class="px-12 py-8 text-xl leading-8 tracking-tight text-gray-900">
        <h3>{{ fiveTestimonials[0].title }}</h3>
        <p>{{ fiveTestimonials[0].body }}</p>
      </blockquote>
      <figcaption class="flex items-center px-6 py-4 border-t gap-x-4 border-gray-900/10">
        <img class="flex-none w-10 h-10 rounded-full bg-gray-50" :src="fiveTestimonials[0].authorImageUrl" :alt="fiveTestimonials[0].authorName" />
        <div class="flex-auto">
          <div class="font-semibold">{{ fiveTestimonials[0].authorName }}</div>
          <div class="text-gray-600">{{ fiveTestimonials[0].authorTitle }}</div>
        </div>
      </figcaption>
    </figure>
    <div v-for="(columnGroup, columnGroupIdx) in testimonialsCols" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
      <div v-for="(testimonial, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonialsCols.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
        <figure class="bg-white shadow-lg rounded-2xl ring-1 ring-gray-900/5">
          <blockquote class="p-6 text-gray-900">
            <h3>{{ testimonial.title }}</h3>
            <p>{{ testimonial.body }}</p>
          </blockquote>
          <figcaption class="flex items-center px-6 py-4 border-t gap-x-4 border-gray-900/10">
            <img class="w-10 h-10 rounded-full bg-gray-50" :src="testimonial.authorImageUrl" alt="" />
            <div>
              <div class="font-semibold">{{ testimonial.authorName }}</div>
              <div class="text-gray-600">{{ testimonial.authorTitle }}</div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  fiveTestimonials: {
    title: string
    body: string
    authorImageUrl: string
    authorName: string
    authorTitle?: string
  }[]
}>()

const testimonialsCols = computed(() => [
  props.fiveTestimonials.slice(1, 3),
  props.fiveTestimonials.slice(3, 5),
])
</script>
